
@keyframes swing {
    0%,30%,50%,70%,100% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(10deg);
    }


    40% {
        transform: rotate(-10deg);
    }

    60% {
        transform: rotate(5deg);
    }

    80% {
        transform: rotate(-5deg);
    }
}

@keyframes sonar {
    0% {
        transform: scale(.9);
        opacity: 1;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}