.sidebar-wrapper {

    .sidebar-header {
        padding: 20px;
        overflow: hidden;

        .user-pic {
            width: 60px;
            padding: 2px;
            margin-right: 15px;
            overflow: hidden;

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }

        .user-info {
            overflow: hidden;

            >span {
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .user-role {
                font-size: 12px;
            }

            .user-status {
                font-size: 11px;
                margin-top: 4px;

                i {
                    font-size: 8px;
                    margin-right: 4px;
                    color: #5cb85c;
                }
            }
        }

    }
}