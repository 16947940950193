.sidebar-wrapper {

    .sidebar-search {

        >div {            
            padding: 1rem 1.2rem;
        }

        input {

            border-radius: 0;
        }

        .input-group {
            flex-wrap: nowrap;
        }

        .input-group-append .input-group-text {
            border-radius: 0;
            border-left: 0;
        }
    }
}