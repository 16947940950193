.sidebar-wrapper {

    .sidebar-footer {

        position: absolute;
        width: 100%;
        bottom: 0;
        display: flex;

        >div {
            flex-grow: 1;
            text-align: center;
            height: $sidebar-footer-height;
            line-height: $sidebar-footer-height;
            position: static;
            display: flex;

            >a {
                flex-grow: 1;
            }

            a .notification {
                position: absolute;
                top: 0;
            }

            &.pinned-footer {
                display: none;
            }
        }

        .dropdown-menu {
            bottom: 36px;
            left: 0 !important;
            top: initial !important;
            right: 0;
            transform: none !important;
            border-radius: 0;
            font-size: .9rem;
        }

        .messages {
            .dropdown-item {
                padding: .25rem 1rem;
            }

            .messages-header {
                padding: 0 1rem;
            }

            .message-content {
                display: flex;

                .pic {
                    width: 40px;
                    height: 40px;
                    overflow: hidden;

                    img {
                        object-fit: cover;
                        height: 100%;
                    }
                }

                .content {
                    line-height: 1.6;
                    padding-left: 5px;
                    width: calc(100% - 40px);

                    .message-title {
                        font-size: 13px;
                    }

                    .message-detail {
                        font-size: 12px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                }
            }

        }

        .notifications {
            .dropdown-item {
                padding: .25rem 1rem;
            }

            .notifications-header {
                padding: 0 1rem;
            }

            .notification-content {
                display: flex;

                .icon {
                    width: 40px;
                    height: 40px;

                    i {
                        width: 35px;
                        height: 35px;
                        text-align: center;
                        line-height: 35px;
                    }
                }

                .content {
                    line-height: 1.6;
                    padding-left: 5px;
                    width: calc(100% - 40px);

                    .notification-time {
                        font-size: .7rem;
                        color: #828282;
                    }

                    .notification-detail {
                        font-size: 12px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }


        .badge-sonar {
            display: inline-block;
            background: #d86703;
            border-radius: 50%;
            height: 8px;
            width: 8px;
            position: absolute;
            top: 0;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                border: 2px solid #d86703;
                opacity: 0;
                border-radius: 50%;
                width: 100%;
                height: 100%;
                animation: sonar 1.5s infinite;
            }
        }


    }
}