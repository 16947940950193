.sidebar-wrapper {
    width: $sidebar-width;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: -$sidebar-width;
    z-index: 999;
    transition: left .3s ease, width .3s ease;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    a {
        text-decoration: none;
        transition: color .3s ease;
    }

    .sidebar-item {
        transition: all .3s linear;
    }

    .sidebar-content {
        max-height: calc(100% -#{$sidebar-footer-height});
        height: calc(100% - #{$sidebar-footer-height});
        position: relative;

        &.desktop {
            overflow-y: hidden;
        }
    }

    .badge {
        border-radius: 0;
    }
}

.sidebar-bg .sidebar-wrapper {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

@import "./sidebar-brand";
@import "./sidebar-header";
@import "./sidebar-search";
@import "./sidebar-menu";
@import "./sidebar-footer";