@mixin themes ($sidebar-bg-color,
$primary-color,
$primary-highlight-color,
$border-color,
$submenu-bg-color,
$menu-icon-bg-color,
$menu-icon-color,
$menu-icon-highlight-color,
$menu-header-color,
$search-input-bg-color,
$footer-bg-color,
$shadow-color,
$scrollbar-color) {
    .sidebar-wrapper {

        background-color: $sidebar-bg-color;

        .sidebar-item {

            border-top: 1px solid $border-color;

            &:first-child {
                border-top: none;
            }
        }

        a:not(.dropdown-item),
        .sidebar-header,
        .sidebar-search input,
        .sidebar-search i {
            color: $primary-color;
        }

        a:not(.dropdown-item):hover,
        .sidebar-menu li.active>a {
            color: $primary-highlight-color;
        }

        .sidebar-search input.search-menu,
        .sidebar-search .input-group-text {
            background-color: $search-input-bg-color;
            border: none;
            box-shadow: none;
            margin-left: 1px;
        }

        .sidebar-menu a:hover i,
        .sidebar-menu a:hover:before,
        .sidebar-menu li.active a i {
            color: $menu-icon-highlight-color;
            // text-shadow: 0px 0px 10px rgba($menu-icon-highlight-color, .5);
        }

        .sidebar-menu ul li a i {
            background-color: $menu-icon-bg-color;
        }


        .sidebar-menu .sidebar-dropdown div {
            background-color: $submenu-bg-color;
        }

        .sidebar-menu .header-menu span {
            //color: $menu-header-color;
        }

        .sidebar-footer {
            background-color: $footer-bg-color;
            box-shadow: 0px -1px 5px $shadow-color;
            border-top: 1px solid $border-color;
        }

        .sidebar-footer>div:first-child {
            border-left: none;
        }

        .sidebar-footer>div:last-child {
            border-right: none;
        }
    }

    &.toggled #close-sidebar {
        color: $primary-color;
    }

    &.toggled #close-sidebar:hover {
        color: $primary-highlight-color;
    }

    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
    .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: $scrollbar-color;
        border-radius: 0;
    }

    .mCSB_scrollTools .mCSB_draggerRail {
        background-color: transparent;
    }


    &.sidebar-bg {

        .sidebar-wrapper {
            &:before {
                background-color: lighten(rgba($sidebar-bg-color, .9), 5%);
            }

            a:not(.dropdown-item),
            .sidebar-header,
            .sidebar-search input,
            .sidebar-search i {
                color: lighten($primary-color, 10%);
            }

            a:not(.dropdown-item):hover,
            .sidebar-menu li.active>a {
                color: lighten($primary-highlight-color, 10%);
            }

            .sidebar-item {

                border-color: lighten($border-color, 10%);
            }

            .sidebar-search input.search-menu,
            .sidebar-search .input-group-text {
                background-color: lighten(rgba($search-input-bg-color, .5), 15%);
            }

            .sidebar-menu .sidebar-dropdown div {
                background-color: lighten(rgba($submenu-bg-color, .5), 15%);
            }

            .sidebar-menu ul li a i {
                background-color: lighten(rgba($menu-icon-bg-color, .5), 15%);
            }

            .sidebar-footer {
                background-color: lighten(rgba($footer-bg-color, .5), 5%);
                box-shadow: 0px -1px 5px rgba($shadow-color, .8);
                border-top: 1px solid lighten($border-color, 5%);
            }

        }

    }
}